<template>
  <div class="whole">
    <Nav></Nav>
    <div class="catalog-whole desktop-present">
      <div class="catalogtitle">
        <div class="icon"></div>
        首页>>{{ catalogTitle }}
      </div>
      <!-- 书画艺术 -->
      <div class="shuhua-con">
        <!-- 轮播部分 -->
        <div class="shuahu-swiper">
          <div class="shuhua-swiper-list" @mouseenter="stopTimer()" @mouseleave="restartTimer()">
            <div
              class="shuhua-swiper-del"
              v-for="(m, shuhuaindex) in swiperList"
              :key="m.id + 'shuhua_' + shuhuaindex"
              :style="{ backgroundImage: `url(` + m.imgURL + `)` }"
              :class="{ active: picIndex == shuhuaindex }"
            >
             <a :href="'post.html?id=' + swiperList[picIndex].postId" target="_blank"></a>
            </div>
          </div>
          <div class="shuhua-circle">
            <div
              v-for="(pic, navIndex) in swiperList"
              :key="pic.id + '_nav' + navIndex"
              class="nav-pic"
              :class="{ active: picIndex == navIndex }"
              @click="scrollTo(navIndex)"
            ></div>
          </div>
        </div>
        <!-- 书画摄影师部分 -->
        <div class="shuhuaUser">
          <div class="onlinePoint">
            <div class="onlinePoint-title">书法</div>
            <div class="onlinePoint-con">
              <div class="onlinePoint-user" v-for="user in userList_zhs" :key="user.id" :style="{ backgroundImage: `url(` + user.imgURL + `)` }">
                <a :href="'userShowDetail.html?id=' + user.id + '&code=' + 5 " target="_blank"></a>
              </div>
            </div>
          </div>
          <div class="onlineArt">
            <div class="onlineArt-title">美术</div>
            <div class="onlineArt-con">
              <div class="onlineArt-user" v-for="user in userList_zhm" :key="user.id" :style="{ backgroundImage: `url(` + user.imgURL + `)` }">
                <a :href="'userShowDetail.html?id=' + user.id  + '&code=' + 7" target="_blank"></a>
              </div>
            </div>
          </div>
        </div>
        <!-- 书画活动 -->
        <div class="shuhua-activity">
          <div class="shuhua-activity-title" @click="onShuhua(227)">书画活动</div>
          <div class="shuhua-activity-con">
            <div
              class="shuhua-activity-list"
              v-for="(k, acindex) in activityList"
              :key="k + 'ac_' + acindex"
              :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
            >
            <a :href="'post.html?id=' + k.postId" target="_blank"></a>
            </div>
          </div>
        </div>
        <!-- 书法作品 -->
        <div class="shufa">
          <div class="shufa-title" @click="onShuhua(228)">书法作品</div>
          <div class="shufa-con">
            <div
              class="shufa-list"
              v-for="(k, acindex) in shufaList"
              :key="k + 'ac_' + acindex"
              :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
            >
             <a :href="'post.html?id=' + k.postId" target="_blank"></a>
            </div>
          </div>
        </div>
        <!-- 美术作品 -->
        <div class="art">
          <div class="art-title" @click="onShuhua(229)">美术作品</div>
          <div class="art-con">
            <div
              class="art-list"
              v-for="(k, acindex) in artList"
              :key="k + 'ac_' + acindex"
              :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
            >
             <a :href="'post.html?id=' + k.postId" target="_blank"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="catalog-whole mobile-present">
      <div class="catalogtitle">
        <div class="icon"></div>
        首页>>{{ catalogTitle }}
      </div>
      <!-- 书画艺术 -->
      <div class="shuhua-con">
        <!-- 轮播部分 -->
        <div class="shuahu-swiper">
          <div class="shuhua-swiper-list" @mouseenter="stopTimer()" @mouseleave="restartTimer()">
            <div
              class="shuhua-swiper-del"
              v-for="(m, shuhuaindex) in swiperList"
              :key="m.id + 'shuhua_' + shuhuaindex"
              :style="{ backgroundImage: `url(` + m.imgURL + `)` }"
              :class="{ active: picIndex == shuhuaindex }"
            >
             <a :href="'post.html?id=' + swiperList[picIndex].postId" target="_blank"></a>
            </div>
          </div>
          <div class="shuhua-circle">
            <div
              v-for="(pic, navIndex) in swiperList"
              :key="pic.id + '_nav' + navIndex"
              class="nav-pic"
              :class="{ active: picIndex == navIndex }"
              @click="scrollTo(navIndex)"
            ></div>
          </div>
        </div>
        <!-- 书画摄影师部分 -->
        <div class="shuhuaUser">
          <div class="onlinePoint">
            <div class="onlinePoint-title">书法</div>
            <div class="onlinePoint-con">
              <div class="onlinePoint-user" v-for="user in userList_zhs" :key="user.id" :style="{ backgroundImage: `url(` + user.imgURL + `)` }">
                <a :href="'userShowDetail.html?id=' + user.id + '&code=' + 5 " target="_blank"></a>
              </div>
            </div>
          </div>
          <div class="onlineArt">
            <div class="onlineArt-title">美术</div>
            <div class="onlineArt-con">
              <div class="onlineArt-user" v-for="user in userList_zhm" :key="user.id" :style="{ backgroundImage: `url(` + user.imgURL + `)` }">
                <a :href="'userShowDetail.html?id=' + user.id  + '&code=' + 7" target="_blank"></a>
              </div>
            </div>
          </div>
        </div>
        <!-- 书画活动 -->
        <div class="shuhua-activity">
          <div class="shuhua-activity-title" @click="onShuhua(227)">书画活动</div>
          <div class="shuhua-activity-con">
            <div
              class="shuhua-activity-list"
              v-for="(k, acindex) in activityList"
              :key="k + 'ac_' + acindex"
              :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
            >
            <a :href="'post.html?id=' + k.postId" target="_blank"></a>
            </div>
          </div>
        </div>
        <!-- 书法作品 -->
        <div class="shufa">
          <div class="shufa-title" @click="onShuhua(228)">书法作品</div>
          <div class="shufa-con">
            <div
              class="shufa-list"
              v-for="(k, acindex) in shufaList"
              :key="k + 'ac_' + acindex"
              :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
            >
             <a :href="'post.html?id=' + k.postId" target="_blank"></a>
            </div>
          </div>
        </div>
        <!-- 美术作品 -->
        <div class="art">
          <div class="art-title" @click="onShuhua(229)">美术作品</div>
          <div class="art-con">
            <div
              class="art-list"
              v-for="(k, acindex) in artList"
              :key="k + 'ac_' + acindex"
              :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
            >
             <a :href="'post.html?id=' + k.postId" target="_blank"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
var timers = []
import RD from '@/api/RD'
import Nav from '@/components/nav.vue'
import Bottom from '@/components/bottom.vue'

export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      catalogTitle: '书画艺术',
      swiperList: [], // 书画轮播
      activityList: [], // 书画活动
      shufaList: [], // 书法作品
      artList: [], //美术作品
      picIndex: 0, // 书画轮播
      userList_zhs: [], // 专业书法
      userList_zhm: [], // 专业美术
    }
  },
  created() {
    this.reload()
  },
  beforeRouteUpdate(to, from, next) {
    if (to.fullPath != from.fullPath) {
      next()
      this.reload()
    }
  },
  methods: {
    reload() {
      var thiz = this
      console.log('quet', thiz.$route.query)
      var catalogId = 226

      RD.index()
        .catalog(catalogId)
        .then((data) => {
          console.log('catalogdata', data)
          if (data.length == 0) {
            thiz.$message('当前栏目无稿件')
          }
          data.forEach((i) => {
            i.posts.forEach((k) => {
              k.imgURL = RD.pic().id(k.frontPicId).mImage_URL()
            })
          })

          timers = setInterval(() => {
            this.swipernext()
          }, 3000)

          thiz.swiperList = data[0].posts
          thiz.activityList = data[1].posts
          thiz.shufaList = data[2].posts
          thiz.artList = data[3].posts

          console.log('posts', thiz.swiperList)
        })
      RD.userShow()
        .tops()
        .then((k) => {
          k.forEach((i) => {
            i.users.forEach((h) => {
              h.imgURL = RD.userShow().id(h.id).headPic_URL()
            })
          })
          for (var subList of k) {
            if (subList.code == 5) {
              this.userList_zhs = subList.users
            }
            if (subList.code == 7) {
              this.userList_zhm = subList.users
            }
          }
        })
    },
    swipernext() {
      this.picIndex++
      if (this.picIndex == this.swiperList.length) {
        this.picIndex = 0
      }
    },
    stopTimer() {
      clearInterval(timers)
    },
    restartTimer() {
      timers = setInterval(() => {
        this.swipernext()
      }, 3000)
    },
    onLookPost(postId) {
      console.log('po', postId)
      this.$router.push({
        path: '/post.html',
        query: { id: postId },
      })
    },
    // 书画二级
    onShuhua(topicId) {
      this.$router.push({
        path: '/fixTopic.html',
        query: { id: topicId },
      })
    },
    scrollTo(navIndex) {
      this.picIndex = navIndex
    },
  },
}
</script>

<style scoped lang="less">
.catalog-whole.desktop-present {
  height: auto;
  background-color: #fff;
  min-height: 61.6rem;
  .catalogtitle {
    height: 4rem;
    text-align: left;
    width: 120rem;
    margin: 0 auto;
    font-size: 1.6rem;
    padding-top: 15px;
    display: flex;
    border-bottom: 1px solid #bfbfbf;
    .icon {
      width: 25px;
      height: 25px;
      background: no-repeat url(../assets/weizhi.png);
      background-size: cover;
      margin-right: 10px;
    }
  }
  .shuhua-con {
    width: 120rem;
    margin: 3rem auto;
    height: 100%;
    overflow: hidden;
    .shuahu-swiper {
      height: 56rem;
      width: 100%;
      position: relative;
      .shuhua-swiper-list {
        height: 100%;
        width: 100%;
        position: relative;
        .shuhua-swiper-del {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          opacity: 0;
          background: no-repeat 50% / cover;
          transition: all 500ms ease-in-out;
          cursor: pointer;
          a {
            display: inline-block;
            width:100%;
            height:100%;
          }
        }
        .shuhua-swiper-del.active {
          opacity: 1;
        }
      }
      .shuhua-circle {
        position: absolute;
        right: 2rem;
        bottom: 3rem;
        width: 16.6rem;
        height: 1.7rem;
        display: flex;
        justify-content: space-between;
        .nav-pic {
          height: 1.25rem;
          width: 1.25rem;
          border-radius: 0.66rem;
          border: 1px solid rgba(255, 255, 255, 0.6);
          margin-right: 0.85rem;
          background: rgba(0, 0, 0, 0.5);
          transition: all 500ms ease-in-out;
          cursor: pointer;
        }
        .nav-pic.active {
          background-color: rgb(23, 47, 184);
          width: 4.2rem;
        }
      }
    }
    .shuhuaUser {
      width: 120rem;
      margin: 0 auto;
      height: auto;
      .onlinePoint {
        height: auto;
        margin: 2rem 0rem;
        .onlinePoint-title {
          height: 5rem;
          line-height: 5rem;
          font-size: 2.5rem;
        }
        .onlinePoint-con {
          min-height: 27rem;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          .onlinePoint-user {
            flex-shrink: 0;
            width: 13rem;
            height: 13rem;
            margin-left: 2rem;
            background: no-repeat 50% / cover;
            cursor: pointer;
            a {
              display: inline-block;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .onlineArt {
        height: auto;
        margin: 20px 0px;
        .onlineArt-title {
          height: 50px;
          line-height: 50px;
          font-size: 2.5rem;
        }
        .onlineArt-con {
          min-height: 270px;
          display: flex;
          flex-wrap: wrap;
          .onlineArt-user {
            width: 130px;
            height: 130px;
            margin-left: 20px;
            background: no-repeat 50% / cover;
            cursor: pointer;
            a {
              display: inline-block;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .shuhua-activity {
      width: 120rem;
      margin: 0 auto;
      .shuhua-activity-title {
        height: 5rem;
        line-height: 5rem;
        font-size: 2.5rem;
        margin: 2remx 0rem;
        cursor: pointer;
      }
      .shuhua-activity-con {
        height: 50rem;
        width: 100%;
        position: relative;
        .shuhua-activity-list {
          cursor: pointer;
          a {
            display: inline-block;
            width:100%;
            height:100%;
          }
        }
        .shuhua-activity-list:nth-child(1) {
          width: 74%;
          height: 100%;
          position: absolute;
          left: 0px;
          top: 0px;
          background: no-repeat 50% / cover;
        }
        .shuhua-activity-list:nth-child(2) {
          position: absolute;
          right: 0px;
          top: 0px;
          height: 48%;
          width: 25%;
          background: no-repeat 50% / cover;
        }
        .shuhua-activity-list:nth-child(3) {
          position: absolute;
          right: 0px;
          top: 25.4rem;
          height: 49%;
          width: 25%;
          background: no-repeat 50% / cover;
        }
      }
    }
    .shufa,
    .art {
      width: 120rem;
      margin: 0 auto;
      .shufa-title,
      .art-title {
        height: 5rem;
        line-height: 5rem;
        font-size: 2.5rem;
        margin: 2rem 0rem;
        cursor: pointer;
      }
      .shufa-con,
      .art-con {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .shufa-list,
        .art-list {
          width: 28.9rem;
          height: 26rem;
          margin-bottom: 1.5rem;
          background: no-repeat 50% / cover;
          cursor: pointer;
          a {
            display: inline-block;
            width:100%;
            height:100%;
          }
        }
      }
    }
  }
}
.catalog-whole.mobile-present {
  height: auto;
  background-color: #fff;
  min-height: 616px;
  width:750px;
  .catalogtitle {
    height: 40px;
    text-align: left;
    width: 750px;
    margin: 0 auto;
    font-size: 1.2rem;
    padding-top: 15px;
    display: flex;
    border-bottom: 1px solid #bfbfbf;
    .icon {
      width: 20px;
      height: 20px;
      background: no-repeat url(../assets/weizhi.png);
      background-size: cover;
      margin-right: 10px;
    }
  }
  .shuhua-con {
    width: 750px;
    margin: 30px auto;
    height: 100%;
    overflow: hidden;
    .shuahu-swiper {
      height: 560px;
      width: 100%;
      position: relative;
      .shuhua-swiper-list {
        height: 100%;
        width: 100%;
        position: relative;
        .shuhua-swiper-del {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          opacity: 0;
          background: no-repeat 50% / cover;
          transition: all 500ms ease-in-out;
          cursor: pointer;
          a {
            display: inline-block;
            width:100%;
            height:100%;
          }
        }
        .shuhua-swiper-del.active {
          opacity: 1;
        }
      }
      .shuhua-circle {
        position: absolute;
        right: 20px;
        bottom: 10px;
        width: 120px;
        height: 20px;
        display: flex;
        justify-content: space-between;
        .nav-pic {
          height: 12.5px;
          flex-shrink: 0;
          width: 12.5px;
          border-radius: 6.6px;
          border: 1px solid rgba(255, 255, 255, 0.6);
          margin-right: 8px;
          background: rgba(0, 0, 0, 0.5);
          transition: all 500ms ease-in-out;
          cursor: pointer;
        }
        .nav-pic.active {
          background-color: rgb(23, 47, 184);
          width: 35px;
        }
      }
    }
    .shuhuaUser {
      width: 750px;
      margin: 0 auto;
      height: auto;
      .onlinePoint {
        height: auto;
        margin: 20px 0px;
        .onlinePoint-title {
          height: 50px;
          line-height: 50px;
          font-size: 22px;
        }
        .onlinePoint-con {
          min-height: 270px;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          .onlinePoint-user {
            flex-shrink: 0;
            width: 130px;
            height: 130px;
            margin-left: 20px;
            background: no-repeat 50% / cover;
            cursor: pointer;
            a {
              display: inline-block;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .amateurArt {
        height: auto;
        margin: 20px 0px;
        .amateurArt-title {
          height: 50px;
          line-height: 50px;
          font-size: 22px;
        }
        .amateurArt-con {
          height: 130px;
          display: flex;
          justify-content: flex-start;
          .amateurArt-user {
            flex-shrink: 0;
            width: 130px;
            height: 130px;
            margin-left: 20px;
            background: no-repeat 50% / cover;
          }
        }
      }
      .amateurPoint {
        height: auto;
        margin: 20px 0px;
        .amateurPoint-title {
          height: 50px;
          line-height: 50px;
          font-size: 2.5rem;
        }
        .amateurPoint-con {
          height: 130px;
          display: flex;
          justify-content: flex-start;
          .amateurPoint-user {
            width: 130px;
            height: 100%;
            margin-left: 20px;
            background: no-repeat 50% / cover;
          }
        }
      }
      .onlineArt {
        height: auto;
        margin: 20px 0px;
        .onlineArt-title {
          height: 50px;
          line-height: 50px;
          font-size: 22px;
        }
        .onlineArt-con {
          min-height: 270px;
          display: flex;
          flex-wrap: wrap;
          .onlineArt-user {
            flex-shrink: 0;
            width: 130px;
            height: 130px;
            margin-left: 20px;
            background: no-repeat 50% / cover;
            cursor: pointer;
            a {
              display: inline-block;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .shuhua-activity {
      width:750px;
      margin: 0 auto;
      .shuhua-activity-title {
        height: 50px;
        line-height: 50px;
        font-size: 22px;
        margin: 20px 0px;
        cursor: pointer;
      }
      .shuhua-activity-con {
        height: 500px;
        width: 750px;
        position: relative;
        overflow: hidden;
        .shuhua-activity-list {
          cursor: pointer;
          a {
            display: inline-block;
            width:100%;
            height:100%;
          }
        }
        .shuhua-activity-list:nth-child(1) {
          width: 450px;
          height: 100%;
          position: absolute;
          left: 0px;
          top: 0px;
          background: no-repeat 50% / cover;
        }
        .shuhua-activity-list:nth-child(2) {
          position: absolute;
          right: 0px;
          top: 0px;
          height: 48%;
          width: 283px;
          background: no-repeat 50% / cover;
        }
        .shuhua-activity-list:nth-child(3) {
          position: absolute;
          right: 0px;
          top: 254px;
          height: 49%;
          width: 283px;
          background: no-repeat 50% / cover;
        }
      }
    }
    .shufa,
    .art {
      width: 750px;
      margin: 0 auto;
      .shufa-title,
      .art-title {
        height: 50px;
        line-height: 50px;
        font-size: 22px;
        margin: 20px 0px;
        cursor: pointer;
      }
      .shufa-con,
      .art-con {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .shufa-list,
        .art-list {
          width: 366px;
          height: 260px;
          margin-bottom: 15px;
          background: no-repeat 50% / cover;
          cursor: pointer;
          a {
            display: inline-block;
            width:100%;
            height:100%;
          }
        }
      }
    }
  }
}
</style>
